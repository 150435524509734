import React, { Component } from "react";
import SanitizedHTML from "react-sanitized-html";

import AlertTable from "./AlertTable";
import _ from "lodash";

class SingleKeyTable extends Component {
  convertKeytoFiled = (key) => {
    if (key && typeof key === "string") {
      return _.startCase((key.split("_").join(" ")));
    } else {
      return "";
    }
  };

  getLink = (lable, link) => {
    return <a href={link}>{lable}</a>;
  };

  getBodyData = () => {
    const { details, keyPairs } = this.props;
    let body = [];
    
    if (details && keyPairs) {
      if (details.length === 1) {
        details.forEach((detailObj) => { 
          keyPairs.forEach((ele) => {
            let rowBody = "";
            if (ele.type === "link") {
              rowBody = this.getLink(detailObj[ele.key], detailObj[ele.key]);
            } else if (ele.type === "html") {
              const blob = _.get(detailObj, ele.key, ""); 
              rowBody = <SanitizedHTML html={_.isArray(blob) ? _.join(blob, " ") : blob} />;
            } else {
              rowBody = detailObj[ele.key];
            }
            body.push([ele.label ? ele.label : this.convertKeytoFiled(ele.key), rowBody]);
          });
        });
      } else if (details.length > 1) {
        for (let i = 0; i < details.length; i++) { 
          keyPairs.forEach((ele) => {
            let rowBody = "";
            if (ele.type === "link") {
              rowBody = this.getLink(details[i][ele.key], details[i][ele.key]);
            } else if (ele.type === "html") {
              const blob = _.get(details[i], ele.key, ""); // Fixed dynamic access
              rowBody = <SanitizedHTML html={_.isArray(blob) ? _.join(blob, " ") : blob} />;
            } else {
              rowBody = details[i][ele.key];
            }
            body.push([ele.label ? ele.label : this.convertKeytoFiled(ele.key), rowBody]);
          });
        }
      }
    }
    return body;
  };
  

  getAlertTableUI = () => {
    const { text, details, keyPairs } = this.props;
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text,
    };

    let headerData = [];

    
    if (details && details.length > 1 && keyPairs) {
      keyPairs.forEach((ele) => {
        let obj = {
          name: ele.label ? ele.label : this.convertKeytoFiled(ele.key),
        };
        headerData.push(obj);
      });
    }

    return (
      <AlertTable
        headerData={[]}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <div>{this.getAlertTableUI()}</div>;
  }
}

export default SingleKeyTable;
