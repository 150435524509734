import React from "react";
const FormatTwo = ({ details }) => {
  if (!details || details.length === 0) {
    return <p>No Data Available</p>;
  }

  const headers = Object.keys(details[0]);
  const hyper_links = [
    "link",
    "report",
    "data_source",
    "links",
    "rating_rationale",
    "timeline_link",
    "FY_link",
  ];

  return (
    <div>
      <table className="mb-2 table table-striped table-bordered responsive">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header.replace(/_/g, " ").replace(/\b\w/g, c => c.toUpperCase())}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {details.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex}>
                  {hyper_links.includes(header) ? (
                    row[header] ? (
                      <a href={row[header]} target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-external-link-alt"></i>
                      </a>
                    ) : (
                      <span>No Link Available</span>
                    )
                  ) : (
                    row[header] || "N/A"
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormatTwo;
