export const SET_CONFIG = 'SET_CONFIG';

export const setConfig = (config) => ({
  type: SET_CONFIG,
  payload: config
});

export const loadConfig = () => {
  return (dispatch) => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => {
        dispatch(setConfig(data));
        if (data.API_URL) {
          localStorage.setItem("key",data.key);
          localStorage.setItem('secret', data.secret);
          localStorage.setItem('API_URL', data.API_URL);
        }
      })
      .catch(error => console.error("Error loading config:", error));
  };
};

